<template>

<div class="columns columns-2 product-meta-price">
	<div class="product-meta"><span v-if="product.in_store > 0">Available In Store</span><span v-else>{{ getDistritutorItemLabel }} #{{ product.product_id }}</span><div v-if="product.upc">UPC: {{ product.upc }}</div></div>
	<div>
		<div v-if="product.price_set.show_regular_price" style="text-align: right;"><span style="font-size: 0.8em;">Was</span> <span v-html="$formatPrice(product.price_set.regular_price)" style="text-decoration: line-through;"></span></div>
		<div class="product-price" v-if="product.price_set.customer_price" v-html="$formatPrice(product.price_set.customer_price)"></div>
	</div>
</div>

</template>

<script>
export default {
	name: 'product-meta-price',
	props: ['product'],
	data() {
		return {}
	}
}
</script>

<style lang="scss" scoped>
</style>
